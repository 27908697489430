<template>

    <i
      class="icon-arrow-left7 text-grey cursor-pointer py-1 mr-2"
      @click="backClick"
      v-tooltip.right="title || $t('common.back')"
    />

</template>

<script>

export default {
  tag: 'el-header-back',
  name: 'Header-back',
  props: {
    back: {
      type: [Function, Boolean],
      default: false,
    },
    title: {
      type: String,
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    backDefault () {
      this.$router.go(-1)
    },
    backClick () {
      if (typeof this.back !== 'boolean') {
        this.back()
      } else {
        this.backDefault()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.btn {
  padding-top: .3em;
  padding-bottom: .3em;
  &[rounded=true] {
    padding-left: 0;
    padding-right: 0;
    width: 31px;
    text-align: center;
  }
}
</style>
