import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'wiki.index', params: { world: world.id } },
    module: 'wiki',
    title: 'modules.wiki.title',
    weight: 10,
    group: 'handbook',
    image: require('./assets/images/module-wiki.svg'),
    description: 'modules.wiki.module_desc',
  },
  {
    to: { name: 'wiki.onboarding', params: { world: world.id } },
    module: 'wiki',
    title: 'modules.wiki.onboarding.title',
    weight: 20,
    group: 'people',
    image: require('./assets/images/module-onboarding.svg'),
    description: 'modules.wiki.onboarding.module_desc',
  },
]

export default links
