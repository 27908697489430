<template>

  <div class="card" v-if="entity">
    <div class="card-body p-0">
      <div class="p-3 cursor-pointer status-choice" @click="changeStatus(false)">
        <div class="font-weight-bold text-success">
          <loader v-if="loading && !isArchived" classes="mr-1 text-grey" />
          <i class="icon-radio-checked2 mr-1" v-else-if="!isArchived" />
          <i class="icon-radio-unchecked text-grey mr-1" v-else />
          {{ $t('moduleentity.status.active.title') }}
        </div>
      </div>
      <hr class="m-0">
      <div class="p-3 cursor-pointer status-choice" @click="changeStatus(true)">
        <div class="font-weight-bold text-grey">
          <loader v-if="loading && isArchived" classes="mr-1 text-grey" />
          <i class="icon-radio-checked2 text-dark mr-1" v-else-if="isArchived" />
          <i class="icon-radio-unchecked text-grey mr-1" v-else />
          {{ $t('moduleentity.status.archived.title') }}
          <div class="small mt-1">{{ $t('moduleentity.status.archived.desc') }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import {
  MODULE_SET_STATUS,
} from '@/store/mutations-types'

export default {
  tag: 'module-entity-status',
  name: 'ModuleEntityStatus',
  props: {
    entity: {
      type: [Object, Array],
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      isArchived: null,
    }
  },
  mounted () {
    this.isArchived = this.entity.archived
  },
  methods: {
    changeStatus (archived) {
      if (this.loading || this.isArchived == archived) {
        return
      }

      this.loading = true
      this.isArchived = archived

      this.$store.dispatch('module/' + MODULE_SET_STATUS, {
        worldId: this.$route.params.world,
        entityId: this.entity.id,
        data: {
          archived: this.isArchived,
        },
      }).then(isArchivedResponse => {
        this.entity.archived = isArchivedResponse
        this.isArchived = isArchivedResponse
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
        this.isArchived = !archived
      })
    },
  },
  computed: {
    isLoadingOrSaving () {
      return this.loading || this.saving
    }
  },
}
</script>

<style lang="scss" scoped>
hr {
  border-width: 1px !important;
}
.status-choice {
  &:hover {
    background: #FAFAFA;
  }
}
</style>
