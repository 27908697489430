import instance from '../../src/api/_instance'

export default {
  /**
  * @vuese
  *  Get method for get all partnership for user connected
  */
  get (worldId) {
    return instance
      .get(`worlds/${worldId}/partnership`)
  },
  /**
  * @vuese
  * This function for add new client
  */
  add (data) {
    return instance
      .post(`worlds/${data.partner_world_id}/partnership/store`, data)
  },
  /**
  * @vuese
  * This function for edit client
  */
  edit (worldId, data) {
    return instance
      .put(`worlds/${worldId}/partnership/update/${data.id}`, data)
  },
  /**
  * @vuese
  * This function for send email to client
  */
  mail (worldId, data) {
    return instance
      .post(`worlds/${worldId}/partnership/mail`, data)
  },

  /**
  * @vuese
  * This function for register client
  */
  verifiedToken (data) {
    return instance
      .post(`partnership/verified_token`, data)
  },
  /**
  * @vuese
  * This function for update client partnership true
  */
  updatePartnership (data) {
    return instance
      .post('partnership/update', data)
  },
  /**
  * @vuese
  * This function for remove client
  */
  userRemove (data) {
    return instance
      .post(`worlds/${data.worldId}/partnership/remove`, data)
  },
  /**
  * @vuese
  *  This function for profile partnership
  */
  profile (worldId, data) {
    return instance
      .post(`worlds/${worldId}/partnership/edit-profile`, data)
  },
  /**
  * @vuese
  * This function get profile partnership data
  */
  userProfile (worldId) {
    return instance
      .post(`worlds/${worldId}/partnership/profile`)
  },

  /**
  * @vuese
  * This function get preferenc Annuaire Data partenaire
  */
  preferenceAnnuaire (worldId, data) {
    return instance
      .post(`worlds/${worldId}/partnership/preference-annuaire`)
  },

  /**
  * @vuese
  *  This function store and update preferenc Annuaire Data partenaire
  */
  preferenceAnnuaireSotre (worldId, data) {
    return instance
      .post(`worlds/${worldId}/partnership/preference-annuaire-store`, data)
  },
  /**
  * @vuese
  * This function to get data for dashboard
  */
  getDash (worldId) {
    return instance
      .post(`worlds/${worldId}/partnership/dashboard`)
  },
  /**
  * @vuese
  * This function to get data from currencies for world
  */
  currencie (worldId) {
    return instance
      .post(`worlds/${worldId}/partnership/currencie`)
  }
}
