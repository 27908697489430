<template>

    <label
      :class="{
        'cursor-pointer d-block d-flex justify-content-between px-2 py-1 w-auto mb-0': true,
        'bg-greylight text-dark': hovered,
      }"
      @mouseenter="hovered = true"
      @mouseleave="hovered = false"
    >
      <div class="d-flex align-items-center">
        <i v-if="option.value" class="small mt-1 icon-checkbox-checked2 mr-1 text-primary"></i>
        <i v-if="!option.value" class="small mt-1 icon-checkbox-unchecked2 mr-1 text-grey"></i>
        <span class="pr-1">{{ option.title }}</span>
      </div>
      <div>
        <span class="badge badge-light" v-if="option.count">{{ option.count }}</span>
      </div>
    </label>

</template>

<script>

export default {
  tag: 'el-filter-checkbox',
  name: 'FilterCheckbox',
  props: ['option'],
  data () {
    return {
      hovered: false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style scoped>
label {
  font-size: 0.9em;
  border-radius: .25em;
}
</style>
