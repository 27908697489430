<template>
    <h4 class="mb-0 text-secondary font-weight-bold">
        <i :class="icon + ' mr-1'" v-if="icon"></i>
        {{ title }}
    </h4>
</template>

<script>
export default {
  tag: 'el-card-title',
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
