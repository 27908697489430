<template>

  <div class="fixed-top sticky-top">
    <div class="small text-center font-weight-bold bg-info text-uppercase" v-if="environment == 'development'">env: {{ environment }}</div>
    <div class="small text-center font-weight-bold bg-warning text-uppercase" v-else-if="environment != 'production'">env: {{ environment }}</div>
    <!-- Main navbar -->
    <div class="navbar-main navbar navbar-expand-md navbar-dark">
      <div class="navbar-brand">
        <router-link tag="a" to="/" class="d-inline-block">
          <img src="@/assets/images/logo.svg" :alt="appName">
        </router-link>
      </div>

      <div class="d-md-none" v-if="isAuthenticated">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile" v-if="!isSidebarLeftOpened">
          <i class="icon-tree5"></i>
        </button>
        <button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
          <i class="icon-paragraph-justify3"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse pr-1" id="navbar-mobile">
        <ul class="navbar-nav d-md-none" v-if="isAuth()">
          <li class="nav-item">
            <a href="#" class="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block">
              <i class="icon-paragraph-justify3"></i>
            </a>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto" v-if="isAuth(false)">

          <el-navbar-modules />

          <li
            class="nav-item dropdown dropdown-notifications"
            v-if="hasWorld && (isPhoneVerified || !isWorldOwner) && !worldSubscriptionEnded"
            v-click-outside="hideNotifications"
          >

            <a href="#" class="navbar-nav-link" @click="showNotifications = !showNotifications">
              <i class="icon-bell3" />
              <span class="d-md-none pl-2">
                {{ $t('notifications.title') }}
              </span>
              <span class="badge badge-pill bg-danger ml-auto ml-md-0" v-if="userHasNotifsForThisWorld">{{ userNotifsForThisWorld.length }}</span>
            </a>

            <div
              :class="{
                'dropdown-menu dropdown-menu-right wmin-md-300 py-0' : true,
                'show' : showNotifications,
              }"
            >

              <div v-if="!userHasNotifsForThisWorld" class="px-3 py-2 text-center text-secondary font-weight-bold">{{ $t('notifications.empty') }}</div>
              <div v-else>

                <div v-for="(notif, notifIndex) in userNotifsForThisWorld" :key="notif.id" :class="{ 'border-bottom': notifIndex < userNotifsForThisWorld.length - 1 }">
                  <component :is="`el-notification-${notif.data.module}-${notif.data.type}`" :notification="notif">
                    <i>No {{ `el-notification-${notif.data.module}-${notif.data.type}` }} component</i>
                  </component>
                </div>

              </div>

              <router-link tag="a" :to="{name: 'notifications.index', params: { world: world.id }}" class="d-inline-block  w-100 border-top small text-center p-2 cursor-pointer text-secondary">
                {{ $t('notifications.view_all') }}
              </router-link>

            </div>

          </li>

          <li class="nav-item dropdown dropdown-user" v-if="isAuth() && isProfileLoaded() && hasWorld">

            <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
              <i v-if="world.logo && world.logo.thumb" class="pr-2">
                <el-avatar id="worldpicture" :src="world.logo.thumb"/>
              </i>
              <span id="worldname" class="px-0">
                {{ world.name }}
                <i v-if="userHasNotifsForOtherWorlds" class="icon-primitive-dot text-danger"/>

                <span class="badge ml-1 text-yellow border-0 font-weight-bold position-absolute" v-if="worldCanManageTemplates">Templates manager</span>
              </span>
            </a>

            <div class="dropdown-menu dropdown-menu-right wmin-md-200 pt-1">

              <div class="current-world font-weight-bold d-flex justify-content-between align-items-center">
                <div>
                  {{ world.name }}
                </div>
                <div class="small font-weight-bold text-right text-grey pl-2">
                  {{ world.id }}
                </div>
              </div>
              <router-link
                tag="a"
                v-for="(item, key) in worldMenu"
                class="dropdown-item"
                :key="key"
                :to="item.path"
                v-if="
                  (!item.need_ownership || item.need_ownership && isWorldOwner) &&
                  (!item.need_subscription || item.need_subscription && worldHasSubscription)
                "
              >
                <i :class="'icon-' + item.icon" v-if="item.icon"></i>
                <i v-if="item.notif" class="notif-icon icon-primitive-dot text-danger"/>
                {{ item.title }}
              </router-link>

              <div v-if="otherWorlds !== false" class="pt-2">
                <div class="dropdown-divider m-0"></div>
                <div class="small text-grey px-1 mt-1 mx-1 py-1 font-weight-semibold">{{ $t('common.change_world') }}</div>
                <div v-for="(item, key) in otherWorlds" :key="key">
                  <a tag="a" class="py-1 dropdown-item font-weight-normal d-flex justify-content-between" @click.prevent="changeWorld(item)">
                    <span>{{ item.name }}</span>
                    <span class="badge badge-pill bg-danger ml-auto ml-md-0" v-if="notifications[item.id] && notifications[item.id].length > 0">{{ notifications[item.id].length }}</span>
                  </a>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item dropdown dropdown-user" v-if="isProfileLoaded()">

            <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
              <el-avatar v-if="user.avatar && user.avatar.thumb" id="authpicture" :src="user.avatar.thumb"/>
              <span id="authname" class="d-md-none">
                {{ renderUsername(user) }}
              </span>
            </a>

            <div class="dropdown-menu dropdown-menu-right wmin-md-200 pt-1">

              <div class="px-2 current-world font-weight-bold d-flex justify-content-between align-items-center">
                <div>
                  {{ renderUsername(user) }}
                </div>
              </div>

              <div v-if="isAuth()">
                <router-link tag="a" v-for="(item, key) in userMenu" :key="key" class="dropdown-item" :to="item.to">
                  <i :class="'icon-' + item.icon" v-if="item.icon"></i>
                  {{ item.title }}
                </router-link>

                <div class="dropdown-divider my-1"></div>
              </div>

              <a class="text-grey dropdown-item small" @click.prevent="logout()">
                <i class="icon-switch"></i>
                {{ $t("user.logout") }}
              </a>
            </div>
          </li>

        </ul>

        <ul class="navbar-nav ml-auto" v-if="isAuthFake">

          <li
            class="nav-item "
          >
            <a href="#" class="navbar-nav-link">
              <i class="icon-bell3" />
            </a>
          </li>


          <li class="nav-item dropdown dropdown-user">
            <a href="#" class="navbar-nav-link dropdown-toggle" data-toggle="dropdown">
              <i class="icon-user p-2 bg-slate circle"></i>
            </a>
          </li>

        </ul>



      </div>
    </div>
    <!-- /main navbar -->
  </div>

</template>

<script>

import vClickOutside from 'v-click-outside'
import { AUTH_LOGOUT, USER_SET_WORLD, GLOBAL_BANNERS } from '../../store/mutations-types'
const $ = require('jquery')

export default {
  tag: 'el-navbar',
  name: 'Navbar',
  props: ['data'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data () {
    return {
      environment: process.env.VUE_APP_ENV,
      showNotifications: false,
      appName: process.env.VUE_APP_TITLE,
      userMenu: [
        {
          title: this.$t("titles.user_profile"),
          to: {name: 'user.profile'},
          icon: 'user',
        },
        {
          title: this.$t("titles.user_security"),
          to: {name: 'user.security'},
          icon: 'lock2',
        }
      ]
    }
  },
  computed: {
    worldSubscriptionEnded () {
      return !this.$store.getters['auth/worldHasSubscription'] && !this.$store.getters['auth/worldOnTrial']
    },
    notifications () {
      return this.$store.getters['auth/getNotificationsByWorld'];
    },
    userHasNotifsForThisWorld () {
      return this.notifications[this.world.id] && this.notifications[this.world.id].length > 0
    },
    userHasNotifsForOtherWorlds () {
      let notifs = false
      if (!this.notifications) {
        return notifs
      }
      Object.keys(this.notifications).forEach( worldId => {
        if (worldId != this.world.id && this.notifications[worldId].length > 0) {
          notifs = true
        }
      })
      return notifs
    },
    userNotifsForThisWorld () {
      return this.notifications[this.world.id] ? this.notifications[this.world.id] : []
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    isPhoneVerified () {
      return this.$store.getters['auth/isPhoneVerified']
    },
    user () {
      return this.$store.getters['auth/getProfile']
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldHasSubscription () {
      return this.$store.getters['auth/worldHasSubscription'] || this.$store.getters['auth/worldOnTrial']
    },
    worldOnTrial () {
      return this.$store.getters['auth/worldOnTrial']
    },
    isSidebarLeftOpened () {
      return this.$store.getters['global/isSidebarLeftOpened']
    },
    currentPath () {
      return this.$route.fullPath
    },
    otherWorlds () {
      if (!this.hasWorld || this.user.worlds.length < 2) {
        return false
      }
      return this.user.worlds.filter((worldItem) => {
        return worldItem.id !== this.world.id;
      });
    },
    hasWorld () {
      return (this.world.id) ? true : false
    },
    worldMenu () {
      if (!this.hasWorld) {
        return {}
      }
      let links = [
        {
          title: this.$t("titles.world_settings"),
          path: this.$router.resolve({name: 'world.settings', params: { world: this.world.id }}).href,
          icon: 'cogs',
          need_ownership: true
        },
        {
          title: this.$t("titles.world_permissions"),
          path: this.$router.resolve({name: 'world.permissions', params: { world: this.world.id }}).href,
          icon: 'key',
          need_ownership: true,
          need_subscription: true
        },
      ]
      if (this.hasStripeKey()) {
        links.push({
          title: this.$t("titles.world_subscription"),
          path: this.$router.resolve({name: 'world.subscription', params: { world: this.world.id }}).href,
          icon: 'credit-card',
          need_ownership: true,
          notif: this.worldOnTrial
        })
      }
      return links
    },
    isWorldOwner () {
      if (!this.hasWorld) {
        return false
      }
      return this.world.pivot.owner
    },
    worldsByKey () {
      return _.keyBy(this.user.worlds, world => world.id)
    }
  },
  methods: {
    hideNotifications() {
      this.showNotifications = false
    },
    logout () {
      this.$store.dispatch('auth/' + AUTH_LOGOUT)
      .then(() => {
        this.$router.push('/login')
      })
    },
    changeWorld (world, redirect = '/') {
      if (world && world.id) {
        this.$store.dispatch('global/' + GLOBAL_BANNERS, world.id)
        this.$store.dispatch('auth/' + USER_SET_WORLD, world)
          .then(() => {
            this.closeAllModals()
            if (redirect) {
              this.$router.push(redirect)
            }
          })
      }
    }
  },
  mounted () {
    // If sidebar is resized by default
    if ($('body').hasClass('sidebar-xs')) {
      revertBottomMenus()
    }

    // Toggle main sidebar class desktop
    $('.sidebar-main-toggle').on('click', (e) => {
      e.preventDefault()

      this.$store.commit('global/changeSidebarLeft', !this.isSidebarLeftOpened)
      // $('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main')
      revertBottomMenus()
    })

    // Toggle main sidebar class mobile
    $('.sidebar-mobile-main-toggle').on('click', (e) => {
      e.preventDefault()

      this.$store.commit('global/changeSidebarLeft', !this.isSidebarLeftOpened)

      $('body').removeClass('sidebar-mobile-secondary sidebar-mobile-right')

      if ($('.sidebar-main').hasClass('sidebar-fullscreen')) {
        $('.sidebar-main').removeClass('sidebar-fullscreen')
      }
    })
  },
  watch: {
    ['$route.params.world'] (newWorldParam, oldWorldParam) {
      if (newWorldParam) {
        if (this.world.id != newWorldParam && this.worldsByKey[newWorldParam]) {
          this.changeWorld(this.worldsByKey[newWorldParam], false)
        } else if (this.world.id != newWorldParam && !this.worldsByKey[newWorldParam]) {
          // world do not exist = redirect home
          this.changeWorld(this.world)
        }
      }
    },
    currentPath () {
      $("#navbar-mobile").first().collapse('hide')
    },
  }
}

// Resize main sidebar

// Flip 2nd level if menu overflows
// bottom edge of browser window
function revertBottomMenus () {
  $('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function () {
    let $this = $(this)
    let navSubmenuClass = 'nav-group-sub'
    let navSubmenuReversedClass = 'nav-item-submenu-reversed'

    if ($this.children('.' + navSubmenuClass).length) {
      if (($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
        $this.addClass(navSubmenuReversedClass)
      } else {
        $this.removeClass(navSubmenuReversedClass)
      }
    }
  })
}

</script>

<style lang="scss" scoped>
.navbar-main {
  z-index: 10000;
}
.navbar-brand {
  padding: 5px 0;
  img {
    height: 2rem;
    margin-top: 4px;
  }
}
.dropdown-item > i[class^="icon-"] {
  min-width: 1.4em;
  opacity: .8;
}
.notif-icon {
  position: absolute;
  left: 29px;
  top: 4px;
}

.current-world {
  padding: .5rem 1rem;
  line-height: 1.3;
}
#worldname .badge {
  position: static;
}
.img-avatar {
  vertical-align: middle !important;
}
.dropdown-menu {
  > div {
    max-height: 500px;
    overflow: auto;
  }
}
</style>
