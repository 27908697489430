<template>

  <div class="card">
    <div class="card-header bg-transparent">
      <i class="icon-info3 mr-2"></i>
      <span class="card-title font-weight-semibold">{{ $t('moduleentity.informations.title') }}</span>
    </div>
    <div class="card-body">
      <div class="mb-2">
        <div v-if="saving">
          <loader classes="text-primary mr-1"></loader>
          {{ $t('moduleentity.informations.saving') }}
        </div>
        <div v-else>
          <i v-if="justSaved" class="icon-checkmark-circle text-success mr-1"></i>
          <span v-if="entity.created_at != entity.updated_at">
            {{ updatedAtAgo }}
          </span>
          <span v-else>
            {{ createdAtAgo }}
          </span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  tag: 'module-entity-informations',
  name: 'ModuleEntityInformations',
  props: ['entity', 'saving'],
  data () {
    return {
      justSaved: false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    createdAtAgo () {
      if (!this.entity) {
        return null
      }
      let date = moment(this.entity.created_at).fromNow()
      return this.$t('moduleentity.informations.saved', { date })
    },
    updatedAtAgo () {
      if (!this.entity) {
        return null
      }
      let date = moment(this.entity.updated_at).fromNow()
      return this.$t('moduleentity.informations.saved', { date })
    },
  },
  watch: {
    saving (val) {
      if (val === false) {
        this.justSaved = true
        setTimeout(() => {
          this.justSaved = false
        }, 2000)
      } else {
        this.justSaved = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 300px;
  max-width: 100%;
}
</style>
