<template>

  <div class="card" v-if="entity">
    <div class="card-header bg-transparent">
      <i class="icon-key mr-2" />
      <span class="card-title font-weight-semibold">{{ $t('moduleentity.permissions.title') }}</span>
    </div>
    <div class="card-body">

      <div class="small font-weight-semibold mb-2">{{ $t('moduleentity.permissions.owner') }}</div>
      <div class="d-flex align-items-center mb-2" v-if="!isCurrentWorldOwner">
        <img :src="entity.author.avatar.thumb" class="mr-2 avatar rounded-circle">
        <span class="">
          {{ entity.author.full_name }}
        </span>
      </div>
      <div v-else>
        <el-select :options="usersToSetOwner" v-model="entity.user_id" />
      </div>

      <div class="mt-2 d-flex cursor-pointer" @click="entity.access_all = !entity.access_all" v-if="isCurrentWorldOwner || currentUser.id == entity.user_id">
        <span>
          <i class="icon-checkbox-unchecked2 mr-1" v-if="!entity.access_all" />
          <i class="icon-checkbox-checked2 mr-1 text-primary" v-if="entity.access_all" />
        </span>
        {{ $t('moduleentity.permissions.all_access') }}
      </div>
      <div v-else-if="entity.access_all">
        <p class="small text-grey">{{ $t('moduleentity.permissions.all_access_viewing') }}</p>
      </div>

      <div class="mt-2" v-if="!entity.access_all">

        <p class="small text-grey" v-if="entity.access.users.length == 0 && entity.access.tribes.length == 0">{{ $t('moduleentity.permissions.empty') }}</p>
        <p class="text-grey small" v-else>{{ $t('moduleentity.permissions.not_empty') }}</p>

        <div class="mt-2">
          <div v-for="user in entity.access.users" class="mb-1" @mouseover="access_invite_user_show_hover = user" @mouseout="access_invite_user_show_hover = null">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img :src="usersKeyed[user].avatar" class="mr-1 avatar rounded-circle">
                <span class="">
                  {{ usersKeyed[user].full_name }}
                </span>
              </div>
              <div v-if="isCurrentWorldOwner || currentUser.id == entity.user_id">
                <i @click="removeUser(user)" v-if="!saving" class="icon-cross2 text-grey cursor-pointer" v-show="user === access_invite_user_show_hover" />
                <loader v-else v-show="user === access_invite_user_show_hover" />
              </div>
            </div>
          </div>
          <el-select v-if="isCurrentWorldOwner || currentUser.id == entity.user_id" :options="usersToInvite" v-model="access_invite_user_id" :placeholder="$t('moduleentity.permissions.user_add')" />
        </div>

        <div class="mt-2">
          <div v-for="tribe in entity.access.tribes" class="mb-1" @mouseover="access_invite_tribe_show_hover = tribe" @mouseout="access_invite_tribe_show_hover = null">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <img :src="tribesKeyed[tribe].avatar" class="mr-1 avatar rounded-circle">
                <span>
                  {{ tribesKeyed[tribe].name }}
                </span>
              </div>
              <div v-if="isCurrentWorldOwner || currentUser.id == entity.user_id">
                <i @click="removeTribe(tribe)" v-if="!saving" class="icon-cross2 text-grey cursor-pointer" v-show="tribe === access_invite_tribe_show_hover" />
                <loader v-else v-show="tribe === access_invite_tribe_show_hover" />
              </div>
            </div>
          </div>
          <el-select v-if="isCurrentWorldOwner || currentUser.id == entity.user_id" :options="tribesToInvite" v-model="access_invite_tribe_id" :placeholder="$t('moduleentity.permissions.tribe_add')" />
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  tag: 'module-entity-permissions',
  name: 'ModuleEntityPermissions',
  props: ['entity', 'tribes', 'users', 'saving'],
  data () {
    return {
      access_invite_user_id: null,
      access_invite_tribe_id: null,
      access_invite_user_show_hover: null,
      access_invite_tribe_show_hover: null,
    }
  },
  mounted () {
  },
  methods: {
    inviteUser (val) {
      if (!val || val === null) {
        return
      }

      this.entity.access.users = Object.assign({}, this.entity.access.users, { [val]: parseInt(val) })

      this.$nextTick(() => {
        this.access_invite_user_id = null
      })
    },
    removeUser (val) {
      if (!val || val === null) {
        return
      }
      let accesses = {...this.entity.access.users}
      this.$delete(accesses, val)
      this.entity.access.users = Object.assign({}, accesses)

    },
    inviteTribe (val) {
      if (!val || val === null) {
        return
      }

      this.entity.access.tribes = Object.assign({}, this.entity.access.tribes, { [val]: parseInt(val) })

      this.$nextTick(() => {
        this.access_invite_tribe_id = null
      })
    },
    removeTribe (val) {
      if (!val || val === null) {
        return
      }
      let accesses = {...this.entity.access.tribes}
      this.$delete(accesses, val)
      this.entity.access.tribes = Object.assign({}, accesses)
    },
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    usersToSetOwner () {
      if (!this.entity || !this.users) {
        return []
      }

      let usersToSetOwner = []
      _.map(this.users, (elem) => {
        usersToSetOwner.push({
          avatar: elem.avatar.thumb,
          value: elem.id,
          title: elem.full_name ? elem.full_name : elem.email,
        })
      })
      return usersToSetOwner
    },
    usersToInvite () {
      if (!this.entity || !this.users) {
        return []
      }

      let usersToInvite = []
      _.map(this.users, (elem) => {
        usersToInvite.push({
          avatar: elem.avatar.thumb,
          value: elem.id,
          title: elem.full_name ? elem.full_name : elem.email,
        })
      })
      return usersToInvite.filter(elem => {
        return typeof this.entity.access.users[elem.value] === 'undefined'
      })
    },
    usersKeyed () {
      let usersKeyed = {}
      _.forEach(this.users, (elem) => {
        usersKeyed[elem.id] = {
          avatar: elem.avatar.thumb,
          full_name: elem.full_name ? elem.full_name : elem.email,
        }
      })
      return usersKeyed
    },
    tribesToInvite () {
      if (!this.entity || !this.tribes) {
        return []
      }

      let tribesToInvite = []
      _.map(this.tribes, (elem) => {
        tribesToInvite.push({
          avatar: elem.avatar.thumb,
          value: elem.id,
          title: elem.name,
        })
      })
      return tribesToInvite.filter(elem => {
        return typeof this.entity.access.tribes[elem.value] === 'undefined'
      })
    },
    tribesKeyed () {
      let tribesKeyed = {}
      _.forEach(this.tribes, (elem) => {
        tribesKeyed[elem.id] = {
          avatar: elem.slug ? require('@/assets/images/categories/' + elem.slug + '.svg') : elem.avatar.thumb,
          name: elem.name,
        }
      })
      return tribesKeyed
    },
  },
  watch: {
    access_invite_user_id (val) {
      if (val) {
        this.inviteUser(val)
      }
    },
    access_invite_tribe_id (val) {
      if (val) {
        this.inviteTribe(val)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 300px;
  max-width: 100%;
}
.avatar {
  width: 34px;
  height: 34px;
}
</style>
