<template>
  <div class="d-inline col-xxl-3 col-lg-4 col-md-6 col-12 mb-3 ">

    <router-link class="card cursor-pointer h-100 mb-3" :to="to" v-if="active">
      <div class="card-body d-flex w-100 justify-content-center align-items-center">
        <span v-if="newlink" class="new badge p-1 bg-info align-self-center ml-auto">NEW</span>
        <router-link
          :to="{name: 'world.permissions', params: { world: world.id }}"
          class="permissions p-3"
          v-if="permissions && permissions == 'custom' && isCurrentWorldOwner"
          v-tooltip="$t('permissions.common.change_permissions')"
        >
          <i class="icon-key text-secondary" />
        </router-link>
        <div class="text-center">
          <div class="mb-3">
            <img :src="image" v-if="image" />
            <i :class="'text-primary big-icon icon-' + icon" v-else />
          </div>
          <h5 class="my-0 text-dark text-uppercase font-weight-bold">
            {{ title }}
          </h5>
          <div class="text-grey mt-1" v-if="description">{{ description }}</div>
        </div>
      </div>
    </router-link>

    <div class="card h-100 mb-3 inactive cursor-pointer" :class="loading ? 'loading' : ''" v-else-if="isCurrentWorldOwner && module" @click="enableModule(module, to)">
      <div class="card-body d-flex w-100 justify-content-center align-items-center">
        <div class="text-center position-relative">
          <div class="mb-3">
            <img :src="image" v-if="image" />
            <i :class="'text-grey big-icon icon-' + icon" v-else />
          </div>
          <h5 class="my-0 text-dark text-uppercase font-weight-bold">
            {{ title }}
          </h5>
          <div class="text-grey mt-1" v-if="description">{{ description }}</div>
          <div class="shadow attraction-enable w-100 text-center btn btn-success py-0">
            <h4 v-if="!loading">{{ $t('common.attraction_enable') }}</h4>
            <loader v-else />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { WORLD_MODULE_ENABLE, WORLD_REQUEST } from '../../store/mutations-types'

export default {
  tag: 'el-attraction-list-item',
  data () {
    return {
      loading: false,
    }
  },
  props: {
    'title': {
      required: true,
      type: String,
    },
    'description': {
      type: String,
    },
    'image': {
      type: String,
    },
    'icon': {
      required: true,
      type: String,
    },
    'module': {
      default: null,
      type: String,
    },
    'permissions': {
      default: null,
      type: String,
    },
    'to': {
      required: true,
      type: Object,
    },
    'active': {
      required: true,
      type: Boolean,
      default: false,
    },
    'newlink': {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
  },
  methods: {
    enableModule (module, to) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.$store.dispatch('world/' + WORLD_MODULE_ENABLE, {
        worldId: this.world.id,
        module: module,
      }).then(data => {
        this.$store.dispatch('world/' + WORLD_REQUEST, this.world.id).then(() => {
          this.notifSuccess(this.$t('common.attraction_enabled'))
          this.$router.push(to)
        })
      })
      .catch(errors => {
        this.notifError(errors)
        this.loading = false
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.big-icon,
img {
  width: 200px;
  line-height: 120px;
  height: 120px;
}
.big-icon {
  text-align: center;
  font-size: 5em;
  text-shadow: .1em .1em 0 rgba(black, .1)
}
img {
  object-fit: contain;
}
.inactive {
  opacity: .6;
  border: 2px dashed rgba(black, .2) !important;
  box-shadow: none !important;
  img {
    filter: grayscale(.7);
  }
  &:hover {
    opacity: 1;
    border-color: rgba(black, .6) !important;
    i,
    img {
      filter: grayscale(.4);
      opacity: .5;
    }
    .attraction-enable {
      opacity: 1;
    }
  }
  &.loading {
    .attraction-enable {
      opacity: 1;
    }
  }
  .attraction-enable {
    opacity: 0;
  }
}
.attraction-enable {
  position: absolute;
  top: 50%;
  left: 0;
  line-height: 46px;
  height: 46px;
  margin-top: -23px;
  * {
    line-height: inherit;
  }
  i {
    line-height: 20px;
    margin-top: -4px;
  }
}
.new {
  position: absolute;
  left: 20px;
  top: 20px;
}
.permissions {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
