/* eslint-disable promise/param-names */

import {
PARTNERSHIP_STORE,
PARTNERSHIP_USERS_REQUEST,
PARTNERSHIP_UPDATE,
PARTNERSHIP_MAIL,
PARTNERSHIP_VERIFY_TOKEN,
AUTH_REGISTER,
AUTH_SUCCESS,
AUTH_ERROR,
AUTH_REQUEST,
PARTNERSHIP_UPDATE_CLIENT,
PARTNERSHIP_REMOVE,
PARTNERSHIP_PROFILE,
PARTNERSHIP_PROFILE_REQUEST,
PARTNERSHIP_PREFERENCE_ANNUAIRE,
PARTNERSHIP_PREFERENCE_ANNUAIRE_STORE,
PARTNERSHIP_MRR_REQUEST,
CURRENCIE_DATA
} from './mutations-types'
import Api from '../../src/api'

const state = {
  active: false
}

const getters = {
  isHasAccessPartnership: state => state.active
}

const actions = {
  // Get all client
  [PARTNERSHIP_USERS_REQUEST]:({}, thisWorld) => {
    return Api.partnership.get(thisWorld.id)
    .then(resp => {
      return resp.data.success
    })
  },
  // Add client
  [PARTNERSHIP_STORE]: ({}, data) => {
    return Api.partnership.add(data.Partnership)
        .then(resp => {
          return resp.data.success
        })
  },
  // Edit client
  [PARTNERSHIP_UPDATE]: ({}, data) => {
    return Api.partnership.edit(data.worldId, data.partnership)
        .then(resp => {
          return resp.data.success
        })
  },
  // Send email
  [PARTNERSHIP_MAIL]: ({}, data) => {
    return Api.partnership.mail(data.worldId, data)
        .then(resp => {
          return resp.data.success
        })
  },
  // Register client
  [PARTNERSHIP_VERIFY_TOKEN]: ({}, data) => {
    return Api.partnership.verifiedToken(data)
        .then(resp => {
          return resp.data.success
        })
  },
  // Update partnership status and add infomation to user
  [PARTNERSHIP_UPDATE_CLIENT]: ({}, data) => {
    return Api.partnership.updatePartnership(data)
        .then(resp => {
          return resp.data.success
        })
  },
  // remove client
  [PARTNERSHIP_REMOVE]: ({}, data) => {
    return Api.partnership.userRemove(data)
        .then(resp => {
          return resp.data.success
        })
  },
  // profile partnership
  [PARTNERSHIP_PROFILE]: ({}, data) => {
    return Api.partnership.profile(data.worldId, data.profile)
        .then(resp => {
          return resp.data.success
        })
  },
  // save profile information
  [PARTNERSHIP_PROFILE_REQUEST]:({}, data) => {
    return Api.partnership.userProfile(data)
        .then(resp => {
          return resp.data.success
        })
  },
  // get data preference annuaire
  [PARTNERSHIP_PREFERENCE_ANNUAIRE]:({}, data) => {
    return Api.partnership.preferenceAnnuaire(data)
        .then(resp => {
          return resp.data.success
        })
  },
  // save data Preferenc aannuaire or update
  [PARTNERSHIP_PREFERENCE_ANNUAIRE_STORE]:({}, data) => {
    return Api.partnership.preferenceAnnuaireSotre(data.worldId, data.data)
        .then(resp => {
          return resp.data.success
        })
  },
  // get data for dashboard
  [PARTNERSHIP_MRR_REQUEST]:({}, worldId) => {
    return Api.partnership.getDash(worldId)
        .then(resp => {
          return resp.data.success
        })
  },
  // get data dashboard
  [CURRENCIE_DATA]:({}, worldId) => {
    return Api.partnership.currencie(worldId)
        .then(resp => {
          return resp.data.success
        })
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
