<template>

  <img v-if="src" :src="src" class="rounded-circle img-avatar" :class="classname">

</template>

<script>
/**
 * @group Core
 * Display user avatar
 */
export default {
  tag: 'el-avatar',
  name: 'Avatar',
  props: {
    // The avatar source path or URL
    src: {
      type: [String, null],
      required: true
    },
    // The avatar size
    size: {
      type: String,
      default: 'small',
    },
    // The avatar image fit mode
    fit: {
      type: String,
      default: 'cover',
    },
  },
  data () {
    return {
    }
  },
  computed: {
    classname () {
      return this.size + ' fit-' + this.fit
    }
  }
}
</script>

<style scoped>
img.fit-cover {
  object-fit: cover;
}
img.fit-contains {
  object-fit: contains;
}
img.xsmall {
  width: 16px;
  height: 16px;
}
img.small {
  width: 34px;
  height: 34px;
}
img.medium {
  width: 50px;
  height: 50px;
}
img.big {
  max-width: 150px;
  width: 100%;
}
</style>
