<template>

    <div class="dropdown">
      <button class="btn border-0 rounded btn-grey dropdown-toggle" type="button" :id="`filter-${id}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span
          :class="{
            'text-dark': true,
          }"
        >
        <span
          :class="{
            'font-weight-bold text-primary': selectedOptions.length > 0,
          }"
        >
          {{ title }}
        </span>
        <span v-if="selectedOptions.length > 0">
           <span class="text-grey">:</span> {{ selectedOptions[0].title }}
        </span>
        <span v-if="selectedOptions.length > 1">
           + {{ selectedOptions.length - 1 }}
        </span>
      </span>
      </button>
      <div class="dropdown-menu" :aria-labelledby="`filter-${id}`">
        <slot></slot>
      </div>
    </div>

</template>

<script>

export default {
  tag: 'el-filter',
  name: 'FilterWrap',
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    selectedOptions () {
      return this.options.filter(option => {
        return option.value === true
      });
    },
  },
}
</script>

<style scoped>
</style>
