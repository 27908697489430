<template>

  <div class="card bg-dark" v-if="entity">
    <div class="card-header bg-transparent">
      <i class="icon-versions mr-2" />
      <span class="card-title font-weight-semibold">{{ $t('moduleentity.template.title') }}</span>
    </div>
    <div class="card-body">
      <slot />
      <div v-if="isLoadingOrSaving">
        <span class="text-primary mr-1"><loader /></span>
        {{ $t('moduleentity.template.enable') }}
      </div>
      <div v-else class="cursor-pointer" @click="toggleTemplate()">
        <i v-if="isTemplate" class="text-primary icon-checkbox-checked2 mr-1 " />
        <i v-else class="icon-checkbox-unchecked2 mr-1 " />
        {{ $t('moduleentity.template.enable') }}
      </div>
    </div>
  </div>

</template>

<script>


import {
  MODULE_SET_TEMPLATE,
} from '@/store/mutations-types'

export default {
  tag: 'module-entity-template',
  name: 'ModuleEntityTemplate',
  props: {
    entity: {
      type: [Object, Array],
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    toggleType: {
      type: String,
      validator: function (value) {
        // La valeur passée doit être l'une de ces chaines de caractères
        return ['classic', 'simple'].indexOf(value) !== -1
      },
      default: 'classic',
    },
  },
  data () {
    return {
      loading: false,
      isTemplate: null,
    }
  },
  mounted () {
  },
  methods: {
    toggleTemplate () {
      if (this.toggleType == 'classic') {
        return this.toggleClassicModuleTemplate()
      } else if (this.toggleType == 'simple') {
        this.entity.template = !this.entity.template
      }
    },
    toggleClassicModuleTemplate () {
      this.loading = true

      this.$store.dispatch('module/' + MODULE_SET_TEMPLATE, {
        worldId: this.$route.params.world,
        entityId: this.entity.id,
        data: {
          template: !this.isTemplate,
        },
      }).then(isTemplateResponse => {
        this.isTemplate = isTemplateResponse
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
  },
  computed: {
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    isLoadingOrSaving () {
      return this.loading || this.saving
    }
  },
  watch: {
    entity: {
      immediate: true,
      deep: true,
      handler (newVal) {
        this.isTemplate = !!(newVal && newVal.template)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
