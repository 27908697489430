<template>

  <div class="mb-3">
    <div class="d-inline-block mr-1 mb-1 search-wrapper">
      <el-filter-search />
    </div>
    <div
      v-for="filter in filters"
      class="d-inline-block mb-1 mr-1"
      v-if="filter.options.length > 1"
    >
      <el-filter
        :title="filter.title"
        :id="filter.key"
        :options="filter.options"
      >
        <template>
          <el-filter-checkbox
            v-for='(option, key) in filter.options'
            v-if="key <= 10"
            :key="key"
            :option="option"
            @click.native="option.value = !option.value"
           >
          </el-filter-checkbox>
        </template>
      </el-filter>
    </div>

    <div
      class="d-inline-block btn btn-grey rounded border-0 bg-transparent"
      @click="$store.commit('global/changeSidebarSearch', ''); cleanFilters()"
      v-if="!areFiltersClean"
    >
      {{ $tc('common.reset_filters') }}
    </div>
  </div>


</template>

<script>

import qs from 'qs'
import _ from 'lodash'
/**
 * @group Core
 * Display Filters
 */
export default {
  tag: 'el-filters',
  name: 'Filters',
  props: {
    // Search input
    search: {
      type: Boolean,
      default: true,
    },
    // Filters array
    filters: {
      type: [Object, Boolean, Array],
    }
  },
  data () {
    return {
    }
  },
  beforeMount () {
    this.setQuerySearch()
    this.setQueryFilters()
  },
  methods: {
    rebuildSearchParams () {
      let queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      if (!this.searchInputValue || this.searchInputValue == '') {
        delete queryStringFilters['search']
      } else {
        queryStringFilters['search'] = this.searchInputValue
      }

      queryStringFilters['filter'] = {}
      if (this.filters && _.toArray(this.filters).length > 0) {
        _.forEach(_.toArray(this.filters), (filter) => {
          if (!queryStringFilters['filter'][filter.key]) {
            queryStringFilters['filter'][filter.key] = []
          }
          _.forEach(filter.options, (option) => {
            if (option.value === true) {
              queryStringFilters['filter'][filter.key].push(option.title)
            }
          })
        })
      }

      let urlQuery = this.getUrlQuery(queryStringFilters)
      if (history.replaceState) {
        history.replaceState({}, null, window.location.origin + window.location.pathname + '?' + urlQuery);
      }
    },
    getUrlQuery (obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push((v !== null && typeof v === "object") ?
            this.getUrlQuery(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&").replace('&&', '&');
    },
    setQuerySearch () {
      const queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (queryStringFilters.search) {
        this.$store.commit('global/changeSidebarSearch', queryStringFilters.search)
      }
    },
    setQueryFilters () {
      const queryStringFilters = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (queryStringFilters.filter) {
        _.forEach(queryStringFilters.filter, (filterValues, filterKey) => {
          if (!Array.isArray(filterValues)) {
            filterValues = [filterValues]
          }
          _.forEach(filterValues, filterValue => {

            let filterIndex = _.findIndex(_.toArray(this.filters), { key: filterKey })

            if (filterIndex > -1) {
              let filterValueIndex = _.findIndex(this.filters[filterIndex].options, { title: filterValue })
              if (filterValueIndex > -1) {
                this.$set(this.filters[filterIndex].options[filterValueIndex], 'value', true)
              }
            }

          });
        });
      }
    },
    cleanFilters () {
      _.forEach(this.filters, filter => {
        _.forEach(filter.options, option => {
          option.value = false
        })
      })
    },
  },
  computed: {
    areFiltersClean () {
      return this.$store.getters['global/areFiltersClean']
    },
    searchInputValue () {
      return this.$store.state.global.sidebarSearch
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.setQueryFilters()
  },
  watch: {
    searchInputValue (newVal, oldVal) {
      this.rebuildSearchParams()
    },
    filters: {
      deep: true,
      handler (newVal, oldVal) {
        if (oldVal === false && newVal !== false) {
          this.setQueryFilters()
        } else {
          this.rebuildSearchParams()
        }
      }
    }
  },
}
</script>

<style scoped>
.search-wrapper {
  position: relative;
  top: 1px;
}

</style>
