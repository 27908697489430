<template>
  <div v-if="isAuthenticated">
    <el-navbar />

    <!-- Page content -->
    <div class="page-content">

      <el-sidebar-left :modulesLoaded="modulesLoaded" v-if="!worldSubscriptionEnded" />
      <div class="content-wrapper position-relative">

        <div v-if="profileLoaded && !hasWorld && !hasInvitations" class="alert bg-warning text-white alert-styled-left m-0 rounded-0">
          {{ $t('user.no_world', { email: userProfile.email }) }}
        </div>
        <div v-if="profileLoaded && hasInvitations">
          <div v-for="invitation in getInvitations" class="alert bg-info text-white alert-styled-left m-0 rounded-0">
            {{ $t('user.world_invite', { world: invitation.world.name }) }}
            <div v-if="loading" class="mt-2 py-2">
              <loader></loader>
            </div>
            <div v-if="!loading" class="mt-2">
              <button @click.prevent="responseInvitation(invitation.world.id, true)" type="button" class="border-white btn text-white btn-success font-weight-bold btn-lg mr-1"><i class="icon-check mr-1"></i> {{ $t('common.accept') }}</button>
              <button @click.prevent="responseInvitation(invitation.world.id, false)" type="button" class="border-0 btn btn-link text-white btn-lg"><i class="icon-cross2 mr-1"></i> {{ $t('common.decline') }}</button>
            </div>
          </div>
        </div>
        <el-global-message-modules v-if="!worldSubscriptionEnded" />
        <el-world-sub-ended v-else />
        <div :key="'slot-content-world-' + delayedWorldId">
          <slot></slot>
        </div>
      </div>

      <el-support v-if="isCurrentWorldOwner" />

    </div>

  </div>
</template>

<script>

import {
  WORLD_INVITATION_RESPONSE,
  WORLD_MODULES,
  AUTH_REFRESH,
  WORLD_USERS_REQUEST,
} from '../store/mutations-types'

export default {
  props: ['sidebar'],
  data () {
    return  {
      loading: false,
      modulesLoaded: false,
      delayedWorldId: 'init',
    }
  },
  methods: {
    responseInvitation (worldId, accept) {
      this.loading = true;

      this.$store.dispatch('world/' + WORLD_INVITATION_RESPONSE, {
        worldId, accept
      }).then(data => {
        this.loading = false
        this.notifSuccess(data)
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    loadModules (worldId) {
      this.modulesLoaded = false
      this.$store.dispatch('world/' + WORLD_MODULES, worldId).then(data => {
        this.modulesLoaded = true
      })
    },
    loadUsers (worldId) {
      this.$store.dispatch('world/' + WORLD_USERS_REQUEST, worldId)
    },
  },
  mounted () {
    this.$store.dispatch('auth/' + AUTH_REFRESH)
    if (this.hasWorld) {
      this.loadModules(this.world.id)
    }
  },
  computed: {
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    modules () {
      return this.$store.getters['world/getModules']
    },
    userProfile () {
      return this.$store.getters['auth/getProfile']
    },
    profileLoaded () {
      return this.$store.getters['auth/isProfileLoaded']
    },
    hasWorld () {
      return this.$store.getters['auth/hasWorld']
    },
    world () {
      return this.$store.getters['auth/getWorld']
    },
    worldId () {
      return this.world.id
    },
    hasInvitations () {
      return this.$store.getters['auth/hasInvitations']
    },
    getInvitations () {
      return this.$store.getters['auth/getInvitations']
    },
    worldSubscriptionEnded () {
      return !this.$store.getters['auth/worldHasSubscription'] && !this.$store.getters['auth/worldOnTrial']
    },
    mainClass () {
      if (this.sidebar) {
        return "col-md-9"
      } else {
        return "col-12"
      }
    }
  },
  watch: {
    worldId (newWorldId, oldWorldId) {
      if (this.hasWorld) {
        // World changed

        // Reload modules
        this.loadModules(newWorldId)
        // Reload users
        this.loadUsers(newWorldId)

        setTimeout(() => {
          this.delayedWorldId = newWorldId
        }, 10)
      }
    }
  }
}
</script>

<style scope>
</style>
