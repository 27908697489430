<template>
  <div class="page-header pt-3 pt-md-3 px-2 px-md-3">

    <div class="page-header-content header-elements-md-inline px-0">
      <div class="d-flex align-items-center mb-1">
        <slot name="left"></slot>
        <el-header-title :title="pageTitle" :titleSuffix="titleSuffix" :favorites="favorites" />
      </div>
      <!-- Action buttons -->
      <div class="d-flex">
        <slot name="actions"></slot>
        <el-header-action v-if="getBanners(currentRouteName, 'ONBOARDING')" :rounded="true" icon="question7" @click.native.prevent="openModal('banner-onboarding-' + getBanners(currentRouteName, 'ONBOARDING')[0].id, true)" classes="btn-secondary" />
      </div>
    </div>

    <slot></slot>

<!--     <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline" v-if="$route.path != '/'">
      <el-header-breadcrumb :breadcrumb="breadcrumb" :title="pageTitle" :titleSuffix="titleSuffix"></el-header-breadcrumb>
      <div class="header-elements d-none">
      </div>
    </div> -->

  </div>
</template>

<script>

import _ from 'lodash'

/**
 * @group Core
 * Display page header
 */
export default {
  tag: 'el-header',
  name: 'Header',
  props: {
    titleSuffix: {
      type: String
    },
    title: {
      type: String,
    },
    favorites: {
      type: Boolean,
      default: true
    },
    // Deprecated
    breadcrumb: {
      type: String
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    // @vuese
    // Get complete title with (automatic) prefix
    pageTitle () {
      if (this.title) {
        return this.title
      } else {
        if (this.$route.meta.module === true) {
          if (typeof this.$route.params.module != "undefined" && typeof this.$route.params.page != "undefined") {
            return this.$t(`modules.${this.$route.params.module}.${this.$route.params.page}.title`)
          } else if (typeof this.$route.params.module != "undefined") {
            return this.$t(`modules.${this.$route.params.module}.title`)
          } else {
            return this.$t(`modules.${this.$route.meta.moduleName}.titles.`+ _.snakeCase(this.$route.name))
          }
        } else {
          return this.$t('titles.' + _.snakeCase(this.$route.name))
        }
      }
    }
  }
}
</script>

<style scoped>
.page-header {
  overflow-x: hidden
}
</style>
