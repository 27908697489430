/* eslint-disable promise/param-names */

import {
  AUTH_REFRESH,
  USER_REFRESH,
  USER_SET_WORLD,
  WORLD_OWNER_STATUS_CHANGE,
  WORLD_USER_INFOS_CHANGE,
  WORLD_INVITATION_SEND,
  WORLD_INVITATIONS_REQUEST,
  WORLD_INVITATION_RESPONSE,
  WORLD_USER_REQUEST,
  WORLD_USERS_REQUEST,
  WORLD_REQUEST,
  WORLD_UPDATE,
  WORLD_USER_REMOVE,
  WORLD_TRIBES_REQUEST,
  WORLD_TRIBES_STORE,
  WORLD_TRIBES_UPDATE,
  WORLD_TRIBES_REMOVE,
  WORLD_TRIBES_DETAILS,
  WORLD_TRIBES_OWNER_STATUS_CHANGE,
  WORLD_TRIBES_USER_ADD,
  WORLD_TRIBES_USER_REMOVE,
  WORLD_SUBSCRIPTION,
  WORLD_SUBSCRIPTION_CANCEL,
  WORLD_PERMISSIONS_REQUEST,
  WORLD_PERMISSIONS_UPDATE,
  WORLD_MODULES,
  WORLD_MODULES_RESET,
  WORLD_MODULE_ENABLE,
  WORLD_BILLS,
  WORLD_USERS_STORE,
  WORLD_USERS_RESET,
  WORLD_TRIAL_EXTEND,
} from '../mutations-types'
import Api from '../../api'

const state = {
  modules: null,
  users: null,
  usersRefreshTime: null,
}

const getters = {
  usersRefreshTime: state => state.usersRefreshTime,
  getModules: state => state.modules,
  getUsers: state => state.users,
}

const actions = {
  [WORLD_REQUEST]: ({dispatch}, worldId) => {
    return Api.world.get(worldId)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_UPDATE]: ({dispatch}, world) => {
    return Api.world.update(world)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_USERS_REQUEST]: ({commit}, worldId) => {
    return Api.world.users(worldId)
      .then(resp => {
        commit(WORLD_USERS_STORE, resp.data.success)
        return resp.data.success
      })
  },
  [WORLD_USER_REQUEST]: ({}, data) => {
    return Api.world.user(data.worldId, data.userId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_INVITATIONS_REQUEST]: ({}, worldId) => {
    return Api.world.invitations(worldId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_INVITATION_SEND]: ({}, data) => {
    return Api.world.invite(data.worldId, data.emails)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_INVITATION_RESPONSE]: ({dispatch}, data) => {
    return Api.world.invitation_response(data.worldId, data.accept)
      .then(resp => {
        return dispatch('auth/' + AUTH_REFRESH, null, { root: true }).then(xx => {
          return resp.data.success
        })
      })
  },
  [WORLD_OWNER_STATUS_CHANGE]: ({}, data) => {
    return Api.world.owner(data.worldId, data.userId, data.status)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_USER_INFOS_CHANGE]: ({commit, dispatch}, data) => {
    return Api.world.user_infos(data.worldId, data.managerId)
      .then(resp => {
        dispatch('auth/' + USER_REFRESH, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_TRIBES_OWNER_STATUS_CHANGE]: ({}, data) => {
    return Api.world.tribes_owner(data.worldId, data.tribeId, data.userId, data.status)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_USER_REMOVE]: ({}, data) => {
    return Api.world.user_remove(data.worldId, data.userId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_USER_REMOVE]: ({}, data) => {
    return Api.world.tribes_user_remove(data.worldId, data.tribeId, data.userId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_USER_ADD]: ({}, data) => {
    return Api.world.tribes_user_add(data.worldId, data.tribeId, data.userId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_REQUEST]: ({}, worldId) => {
    return Api.world.tribes(worldId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_DETAILS]: ({}, data) => {
    return Api.world.tribes_details(data.worldId, data.tribeId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_STORE]: ({}, data) => {
    return Api.world.tribes_add(data.worldId, data.name)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_UPDATE]: ({}, data) => {
    return Api.world.tribes_edit(data.worldId, data.tribeId, data.name)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIBES_REMOVE]: ({}, data) => {
    return Api.world.tribes_remove(data.worldId, data.tribeId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_SUBSCRIPTION]: ({dispatch}, data) => {
    return Api.world.subscription(data.worldId, data.subscriptionId)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_SUBSCRIPTION_CANCEL]: ({dispatch}, worldId) => {
    return Api.world.subscription_cancel(worldId)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_PERMISSIONS_REQUEST]: ({dispatch}, worldId) => {
    return Api.world.permissions(worldId)
      .then(resp => {
        return resp.data.success
      })
  },
  [WORLD_TRIAL_EXTEND]: ({dispatch}, worldId) => {
    return Api.world.trial_extend(worldId)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_PERMISSIONS_UPDATE]: ({dispatch}, data) => {
    return Api.world.permissions_update(data.worldId, data.permissions)
      .then(resp => {
        dispatch('auth/' + USER_SET_WORLD, resp.data.success, { root: true })
        return resp.data.success
      })
  },
  [WORLD_MODULES]: ({commit, dispatch}, worldId) => {
    return Api.world.modules(worldId)
      .then(resp => {
        commit(WORLD_MODULES, resp.data.success)
        return resp.data.success
      })
  },
  [WORLD_MODULE_ENABLE]: ({commit, dispatch}, data) => {
    return Api.world.module_enable(data.worldId, data.module)
      .then(resp => {
        commit(WORLD_MODULES, resp.data.success)
        return resp.data.success
      })
  },
  [WORLD_BILLS]: ({commit, dispatch}, worldId) => {
    return Api.world.bills(worldId)
      .then(resp => {
        return resp.data.success
      })
  },

  [WORLD_MODULES_RESET]: ({commit, dispatch}) => {
      commit(WORLD_MODULES_RESET)
      return null;
  },
  [WORLD_USERS_RESET]: ({commit, dispatch}) => {
      commit(WORLD_USERS_RESET)
      return null;
  },
}

const mutations = {
  [WORLD_MODULES]: (state, val) => {
    state.modules = val
  },
  [WORLD_MODULES_RESET]: (state) => {
    state.modules = null
  },
  [WORLD_USERS_STORE]: (state, val) => {
    state.users = val
    state.usersRefreshTime = new Date().getTime()
  },
  [WORLD_USERS_RESET]: (state) => {
    state.users = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
