<!-- Component Responsable action and list users in world how has access to world ( owner, office, manager) -->
<template>
  <span :class="{'user flex-item': !delege, 'btn delege btn-lg mr-1': delege}">
    <div class="user-dropdown-link-wrapper">
      <a href="#" class="text-grey user-dropdown-link px-2 dropdown-toggle caret-0 d-block" data-toggle="dropdown" v-if="!(userAuth.id == owner && owner != creator) && !delege">
        <i class="icon-user" v-if="owner == creator || owner == null"></i>
        <i class="icon-users pl-1" v-if="owner == -1"></i>
        <img :src="TaskUser(owner)[0].avatar.thumb" class="user-img circle" v-if="owner != creator && owner != -1 && TaskUser(owner)[0]">
        <div class="user-badge text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="owner && TaskUser(owner)[0] && !TaskUser(owner)[0].avatar.thumb">
            {{ TaskUser(owner)[0].first_name !== ''? getInitials(TaskUser(owner)[0].first_name): getInitials(TaskUser(owner)[0].email) }}
        </div>
      </a>
      <a href="#" class="text-grey user-dropdown-link px-2 dropdown-toggle caret-0 d-block" data-toggle="dropdown" v-if="userAuth.id == owner && owner != creator && !delege">
        <img :src="userAuth.avatar.thumb" class="user-img circle">
        <div class="user-badge text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="owner && userAuth && !userAuth.avatar.thumb">
            {{ getInitials(userAuth.first_name) !== ''? getInitials(userAuth.first_name): getInitials(user.email) }}
        </div>
      </a>
      <a href="#" class="text-grey user-dropdown-link px-2 dropdown-toggle caret-0 d-block" data-toggle="dropdown" v-if="delege">
        <div class="border-white btn text-white btn-success font-weight-bold btn-lg mr-1">
          {{ $t('modules.actionsboard.delege_to_user') }}
        </div>
      </a>
      <div class="dropdown-menu py-1" >
        <template v-for="(user, index) in users">
          <a hreconvertTimef="#" class="dropdown-item" v-if="user.id != creator" :key="index" @click="responsibleTask(index, user.id, $event)">
            <img :src="user.avatar.thumb" class="user-img circle" v-if="user.avatar.thumb">
            <div class="user-badge mr-2 text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="!user.avatar.thumb">
              {{ TaskUser(owner)[0].first_name !== ''? getInitials(user.full_name): getInitials(user.email) }}
            </div>
            {{ user.full_name !== '' ? user.full_name: user.email }}
          </a>
        </template>
        <div class="dropdown-divider"/>
        <a href="#" class="dropdown-item text-grey" @click="responsibleTask(null, null, $event)">
          <i class="icon-user pl-1"></i>
          {{ $t('common.clear') }}
        </a>
      </div>
    </div>
  </span>
</template>


<script>

import store from '@/store'
/**
 * @group _ Module Actionsboard
 * This component display list users
 */
export default {
  name: 'TaskResponsible',
  /**
  * @vuese
  * type : String, users: object Users, creator: id creator action, delegeUser: user owner , actionId: id action
  */
  props: ['type', 'users', 'owner', 'creator', 'delegeUser', 'actionId'],
  data () {
    return {
      module: 'modules.'+this.$route.meta.moduleName,
      userAuth: store.getters['auth/getProfile'],
      delege: false
    }
  },
  mounted() {
    this.delege = this.delegeUser ? this.delegeUser: false
  },
  methods: {
    /**
     * @vuese
     * Get first caracter user firt name and last name
     * @arg String
     */
    getInitials (string) {
      let initials = ''
      string.split(' ').forEach(part => {
        initials += part.substring(0, 1)
      })
      return initials
    },
    /**
     * @vuese
     *  Return user object selected in action
     * @arg integer
     */
    TaskUser (user) {
      let users = this.users
      return users.filter(item => {
        if(item.id == user) {
          return item
        }
      })
    },
    /**
     * @vuese
     *  Select new responsable to action to emeit update owner
     * @arg integer, object, integer
     */
    responsibleTask (responsible, user, e) {
      e.preventDefault()
      if(this.delege) {
        return this.$emit('responsibleTask', user, this.actionId )
      }
      this.$emit('responsibleTask', user )
    },
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown-link {
  line-height: 35px;
}
.user-img {
  width: 20px;
  height: 20px;
}
.flex-item {
  display: flex;
  align-items: center;
}
.btn:hover {
  &.delege {
    box-shadow: none !important;
  }
}
</style>
