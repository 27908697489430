import store from '../../src/store'

let world = store.getters['auth/getWorld']
let modules = store.getters['world/getModules']

let links = [
  {
    to: { name: 'actionsboard.index', params: { world: world.id } },
    module: 'actionsboard',
    group: 'actions',
    title: 'modules.actionsboard.page_titles.actions',
    weight: 1,
    image: require('./assets/images/actions.svg'),
    description: 'modules.actionsboard.module_descs.actions',
  },
  {
    to: { name: 'actionsboard.fastsindex', params: { world: world.id } },
    module: 'actionsboard',
    group: 'actions',
    title: 'modules.actionsboard.page_titles.fasts',
    weight: 2,
    image: require('./assets/images/fasts.svg'),
    description: 'modules.actionsboard.module_descs.fasts',
  },
]

export default links
