<template>
  <div v-if="showElement && permissionActionsboard" class="message-global">
    <div v-for='(item, key) in allActions' >
      <div class="alert bg-info text-white alert-styled-left m-0 rounded-0" v-if='getCreator(item.item, item.type) && key === 0 && item.type === "actionsAssign"'>
        {{ $t(module + '.new_actions_to_activeted.first') }}
        <span class="font-weight-bold">{{ item.item.name }}</span>
        {{ $t(module + '.new_actions_to_activeted.second') }} <span class="font-weight-bold">{{ getCreator(item.item, item.type) }}</span>
        <div class="mt-1">
          <button @click.prevent="actionValidation(true, item.item)" type="button" class="border-white btn text-white btn-success font-weight-bold btn-lg mr-1"><i class="icon-check mr-1"></i> {{ $t(module + '.accept') }}</button>
          <TaskResponsible :users="usersList" :delegeUser='true' @responsibleTask="delegeTask" :actionId='item.item.id'></TaskResponsible>
          <button @click.prevent="actionValidation(false, item.item)" type="button" class="border-0 btn btn-link text-white btn-lg"><i class="icon-cross2 mr-1"></i> {{ $t('common.decline') }}</button>
        </div>
      </div>
      <div class="alert bg-warning text-white alert-styled-left m-0 rounded-0" v-if='key === 0 &&( item.type === "actionsRefuse" || item.type === "actionDeliyEnd")'>
        {{ $t(module + '.'+item.type+'.first') }}
        <span class="font-weight-bold">{{ item.item.name }}</span>
        {{ $t(module + '.'+item.type+'.second') }}
        <div class="mt-1">
          <TaskResponsible :users="usersList" :delegeUser='true' @responsibleTask="delegeTask" :actionId='item.item.id'></TaskResponsible>
          <button @click.prevent="actionValidationCreator(false, item.item)" type="button" class="border-0 btn btn-link text-white btn-lg"><i class="icon-cross2 mr-1"></i> {{ $t(module + '.common.annule') }}</button>
        </div>
      </div>
      <div class="alert bg-success text-white alert-styled-left m-0 rounded-0" v-if='key === 0 && item.type === "actionsSuccess"'>
        {{ $t(module + '.actions_success.first') }}
        <span class="font-weight-bold">{{ item.item.name }}</span>
        {{ $t(module + '.actions_success.second') }}
        <span class="font-weight-bold">{{ getCreator(item.item, item.type) }}</span>
        <div class="mt-1">
          <button @click.prevent="actionValidationCreator(false, item.item)" type="button" class="border-0 btn btn-link text-white btn-lg"><i class="icon-cross2 mr-1"></i> {{ $t(module + '.common.ferme') }}</button>
        </div>
      </div>
      <div class="alert bg-warning text-white alert-styled-left m-0 rounded-0" v-if='key === 0 && item.type === "actionsDelege"'>
        {{ $t(module + '.new_actions_delege.first') }}
        <span class="font-weight-bold">{{ item.item.name }}</span>
        {{ $t(module + '.new_actions_delege.second') }} <span class="font-weight-bold">{{ getCreator(item.item, item.type)  }}</span>
        <div class="mt-1">
          <button @click.prevent="actionValidation(false, item.item)" type="button" class="border-white btn text-white btn-success font-weight-bold btn-lg mr-1"><i class="icon-check mr-1"></i> {{ $t(module + '.accept') }}</button>
          <TaskResponsible :users="usersList" :delegeUser='true' @responsibleTask="delegeTask" :actionId='item.item.id'></TaskResponsible>
        </div>
      </div>
      <div class="alert bg-info text-white alert-styled-left m-0 rounded-0" v-if='key === 0 && item.type === "actionsTribes"'>
        {{ $t(module + '.actions_tribe.first') }}
        <span class="font-weight-bold">{{ item.item.name }}</span>
        {{ getTag(item.item.tribus).count.length == 1 ? $t(module + '.actions_tribe.second'):  $t(module + '.actions_tribe.seconds')}}
        {{ getTag(item.item.tribus).title }}
        <div class="mt-1">
          <button @click.prevent="actionValidationTribe(true, item.item, getTag(item.item.tribus).count)" type="button" class="border-white btn text-white btn-success font-weight-bold btn-lg mr-1"><i class="icon-check mr-1"></i> {{ $t(module + '.accept') }}</button>
          <button @click.prevent="actionValidationTribe(false, item.item, getTag(item.item.tribus).count)" type="button" class="border-0 btn btn-link text-white btn-lg"><i class="icon-cross2 mr-1"></i> {{ $t('common.decline') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ACTION_REQUEST_ACTIONS_PARTAGES, ACTION_ACTIVE_OWNER, ACTION_REQUEST_ACTIONS_UPDATE} from '../mutations-types'
import TaskResponsible from '../pages/component/_TaskResponsible'
/**
 * @group _ Module Actionsboard
 * This component for display notifications
 */
export default {
  tag: 'actionsboard-global-message',
  name: 'actionsboard-global-message',
  props: ['data'],
  components: {
    TaskResponsible
  },
  data () {
    return {
      showElement: false,
      module: 'modules.actionsboard',
      actionsAssign: [],
      actionsRefuse: [],
      actionsSuccess: [],
      actionsDelege: [],
      actionsTribes: [],
      actionDeliyEnd: [],
      creator: {},
      allActions: []
    }
  },
  computed: {
    worldId () {
      return this.$store.getters['auth/getWorld'].id
    },
    users () {
      let users = this.$store.getters['world/getUsers']
      if (!users) {
        users = []
      }
      users.forEach(user => {
        user.rendered_name = user.full_name || user.email
      })
      return users
    },
    /**
     * @vuese
     * get list users
     * @arg
     */
    usersList () {
      if(this.allActions.length > 0) {
        return this.users.filter(item => item.id != this.allActions[0].item.creator)
      }
      return []
    },
    /**
     * @vuese
     * Check permission access to actionsboard
     * @arg
     */
    permissionActionsboard () {
      return this.$store.getters['auth/getWorld'].modules.actionsboard
    }
  },
  mounted () {
    if(this.permissionActionsboard) {
      this.getDataActions()
    }
  },
  methods: {
    /**
     * @vuese
     * Get list actions assign to user conected
     * @arg
     */
    getDataActions () {
      this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_PARTAGES, {id: this.worldId, entity: 'Actions'}).then(actions => {
        this.actionsAssign = actions.data
        this.actionsRefuse = actions.notifieCreator
        this.actionsSuccess = actions.notifieCreatorSuccess
        this.actionsDelege = actions.notifieActionsDelege
        this.actionsTribes = actions.notificationTribe
        this.actionDeliyEnd = actions.notificaTiontimeDeliyEnd

        this.actionsAssign.map(item => {
          this.allActions.push({type: 'actionsAssign', item})
        })
        this.actionsRefuse.map(item => {
          this.allActions.push({type: 'actionsRefuse', item})
        })
        this.actionsSuccess.map(item => {
          this.allActions.push({type: 'actionsSuccess', item})
        })
        this.actionsDelege.map(item => {
          this.allActions.push({type: 'actionsDelege', item})
        })
        this.actionsTribes.map(item => {
          this.allActions.push({type: 'actionsTribes', item})
        })
        this.actionDeliyEnd.map(item => {
          this.allActions.push({type: 'actionDeliyEnd', item})
        })
        this.allActions =  _.sortBy(this.allActions, [item => item.item.updated_at, item => item.item.updated_at])
        this.allActions = this.allActions.reverse()
        this.showElement = (this.allActions.length > 0) ? true: false
      })
    },
    /**
     * @vuese
     * valide action
     * @arg
     */
    actionValidation (value, action) {
      this.$store.dispatch('actionsboard/' + ACTION_ACTIVE_OWNER, {id: this.worldId, action: action.id, status: value }).then(resp => {
        if(value) {
          this.$store.commit('actionsboard/pushTodos', [action])
        }
        this.allActions = this.allActions.filter(item => item.item.id != action.id)
        this.showElement = (this.allActions.length > 0) ? true: false
        this.actionsAssign = this.actionsAssign.filter(item => item.id != action.id)
      })
    },
    /**
     * @vuese
     * Dele action to other owner
     * @arg
     */
    delegeTask (user, id) {
      let object = {
        value: user,
        key: 'owner',
        id:id,
        delege: true
      }
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
        this.actionsAssign = this.actionsAssign.filter(item => item.id != id)
        this.allActions = this.allActions.filter(item => item.item.id != id)
        this.showElement = (this.allActions.length > 0) ? true: false
      })
    },
    /**
     * @vuese
     * get creator actionobject
     * @arg
     */
    getCreator (item, type) {
      let theUser = type === 'actionsAssign' ? item.oldOwner : item.owner
      theUser = theUser != null ? theUser : item.creator
      let userIs = this.users.filter(user => {
        if(user.id == theUser) {
          return user
        }
      })
      if(userIs[0]) {
        if(userIs[0].full_name != '' && userIs[0].full_name != null) {
          return userIs[0].full_name
        } else {
          return userIs[0].email
        }
      } else {
        return null
      }
    },
    /**
     * @vuese
     * valide action by add to my action or refus
     * @arg
     */
    actionValidationCreator (value, action) {
      this.$store.dispatch('actionsboard/' + ACTION_ACTIVE_OWNER, {id: this.worldId, action: action.id, status: value }).then(resp => {
        this.actionsRefuse = this.actionsRefuse.filter(item => item.id != action.id)
        this.allActions = this.allActions.filter(item => item.item.id != action.id)
        this.showElement = (this.allActions.length > 0) ? true: false
      })
    },
    /**
     * @vuese
     * get tage list Tribes how added to action
     * @arg array
     */
    getTag (Tribes) {
      let title = '';
      let count = []
      Tribes.map((item, key) => {
        this.$store.state.actionsboard.tagsTribes.map((tribe) => {
          if(tribe.id == item && Tribes.length > key+1) {
            count.push(item)
            return title += tribe.name + ' , '
          }
          else if(tribe.id == item && Tribes.length === key+1) {
            count.push(item)
            return title += tribe.name
          }
        })
      })
      return {count, title}
    },
    /**
     * @vuese
     *  validation action tribus
     * @arg
     */
    actionValidationTribe (value, action, tribe) {
      this.$store.dispatch('actionsboard/' + ACTION_ACTIVE_OWNER, {id: this.worldId, action: action.id, status: value, typeIs: 'Tribe', list: tribe }).then(resp => {
        if(!value) {
          this.$store.commit('actionsboard/deleteTodo', action.id)
        }
        this.allActions = this.allActions.filter(item => item.item.id != action.id)
        this.showElement = (this.allActions.length > 0) ? true: false
      })
    }
  }
}
</script>

<style scoped>
</style>
