import instance from './_instance'

export default {

  list (worldId, moduleId, entityType) {
    return instance
      .get(`worlds/${worldId}/module/${moduleId}/${entityType}`)
  },

  archives (worldId, moduleId, entityType) {
    return instance
      .get(`worlds/${worldId}/module/${moduleId}/${entityType}/archives`)
  },

  add (worldId, moduleId, entityType, data) {
    return instance
      .post(`worlds/${worldId}/module/${moduleId}/${entityType}`, data)
  },

  edit (worldId, moduleId, entityType, data) {
    let id = data.get ? data.get('id') : data.id
    return instance
      .post(`worlds/${worldId}/module/${moduleId}/${entityType}/${id}`, data)  // multipart issue fix, should be PUT method
  },

  remove (worldId, moduleId, entityType, id) {
    return instance
      .delete(`worlds/${worldId}/module/${moduleId}/${entityType}/${id}`)
  },

  refreshAccessKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/accesskey/refresh`)
  },

  refreshPublicKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/publickey/refresh`)
  },

  cancelPublicKey (worldId, entityId) {
    return instance
      .get(`worlds/${worldId}/data/${entityId}/publickey/cancel`)
  },

  getByPublicKey (worldId, publickey) {
    return instance
      .get(`worlds/${worldId}/data/${publickey}`)
  },

  setTemplate (worldId, moduleId, entityId, data) {
    return instance
      .put(`worlds/${worldId}/data/${entityId}/template`, data)
  },

  setStatus (worldId, moduleId, entityId, data) {
    return instance
      .put(`worlds/${worldId}/data/${entityId}/status`, data)
  },

}
