<template>

  <container
    :is="isInvitation ? 'div' : 'router-link'"
    v-if="user"
    :class="{
      'card': true,
      'cursor-pointer': !isInvitation,
    }"
    tag="div"
    :to="isInvitation ? null : $router.resolve({ name:'world.users.profile', params: { world: world.id, user: user.id } }).href"
  >
    <div class="card-body">
        <div class="row align-items-center">
          <div class="col-md-2 col-lg-2 col-xl-1 text-center avatar">
            <i class="icon-hour-glass2 text-grey" v-if="user.invitation"/>
            <div v-else>
              <div
                v-if="!user.invitation"
                v-tooltip="$t('user.list.view_profile')"
              >
                <el-avatar :src="user.avatar.thumb || user.avatar"/>
              </div>
            </div>
          </div>
          <div class="col-md">
            <div class="font-weight-bold" v-if="user.first_name || user.owner">
              {{ user.first_name }} {{ user.last_name }}
              <span class="text-white badge badge-info" v-if="user.owner">{{ $t('user.list.owner') }}</span>
            </div>
            <div class="font-weight-bold text-grey" v-else-if="user.invitation">
              <div class="badge badge-info">{{ $t('user.list.invited') }}</div>
            </div>
            <div class="font-weight-bold text-grey" v-else>-</div>
            <div class="mt-1 text-grey" v-if="user.position">{{ user.position }}</div>
          </div>
          <div class="col-md">
            <div class="" v-if="user.email"><i class="text-grey mr-1 icon-envelop3"></i> {{ user.email }}</div>
            <div class="mt-1 " v-if="user.phone_number"><i class="text-grey mr-1 icon-phone2"></i> {{ user.phone_number }}</div>
          </div>
          <div class="col-md text-right">

            <el-table-action
              v-if="changeOwnerStatus && isWorldOwner && user.id !== currentUserId && !user.owner && !user.invitation"
              @click.prevent.native="changeOwnerStatus(user.id, true)"
              :title="$t('user.list.set_owner')"
              icon="icon-magic-wand"
            />
            <el-table-action
              v-if="changeOwnerStatus && isWorldOwner && user.id !== currentUserId && user.owner && !user.invitation"
              @click.prevent.native="changeOwnerStatus(user.id, false)"
              :title="$t('user.list.unset_owner')"
              icon="icon-magic-wand"
            />
            <el-table-action
              v-if="resendUserPassword && isWorldOwner && user.id !== currentUserId && !user.invitation"
              @click.prevent.native="resendUserPassword(user.email)"
              :title="$t('user.list.resend_password')"
              icon="icon-lock2"
            />
            <el-table-action
              v-if="confirmUserRemove && isWorldOwner && user.id !== currentUserId && !user.invitation"
              @click.prevent.native="confirmUserRemove(user.id)"
              :title="$t('user.list.remove_user')"
              icon="icon-bin"
            />
            <el-table-action
              v-if="relaunchInvitation && isWorldOwner && user.invitation"
              @click.prevent.native="relaunchInvitation(user.email)"
              :title="$t('user.list.relaunch_invitation')"
              icon="icon-reload-alt"
            />
            <el-table-action
              v-if="removeInvitation && isWorldOwner && user.invitation"
              @click.prevent.native="removeInvitation(user.invitation_id)"
              :title="$t('user.list.remove_invitation')"
              icon="icon-bin"
            />
            <i class="icon-arrow-right8 p-2" v-if="!isInvitation"/>

          </div>
        </div>
      </div>
    </div>
  </container>

</template>

<script>
export default {
  tag: 'el-user-teaser',
  props: {
    user: {
      type: Object,
      required: true,
    },
    changeOwnerStatus: {
      type: Function,
    },
    resendUserPassword: {
      type: Function,
    },
    confirmUserRemove: {
      type: Function,
    },
    relaunchInvitation: {
      type: Function,
    },
    removeInvitation: {
      type: Function,
    },
  },
  computed: {
    world () {
      return this.$store.getters['auth/getWorld']
    },
    isInvitation () {
      return this.user.id ? false : true
    },
    isWorldOwner () {
      return this.$store.getters['auth/isWorldOwner']
    },
    currentUserId () {
      return this.$store.getters['auth/getId']
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
