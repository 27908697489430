import store from '../store'

let actions = {
  needAuthComplete: (to, from, next) => {

    // check subscription
    if (
      store.getters['auth/hasWorld'] &&
      !store.getters['auth/worldOnTrial'] &&
      !store.getters['auth/worldHasSubscription']
    ) {
      if (store.getters['auth/isWorldOwner']) {
        next('/world/' + store.getters['auth/getWorld'].id + '/subscription')
      }
    }

    // If user owns the world, need phone to be verified
    let phoneOK = false
    if (store.getters['auth/isWorldOwner'] === false) {
      phoneOK = true
    } else if (store.getters['auth/isWorldOwner'] && store.getters['auth/isPhoneVerified']) {
      phoneOK = true
    }

    let phoneNeedsValidation = false

    if (store.getters['auth/isAuthenticated'] && (phoneOK || !phoneNeedsValidation)) {
      next()
      return
    } else if(store.getters['auth/isAuthenticated'] && !phoneOK) {
      next('/phone_verification')
      return
    }
    // If false
    next('/login')
  },

  needAnonymous: (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
      next()
      return
    }
    next('/')
  },

  needAuthWithoutPhone: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && !store.getters['auth/isPhoneVerified']) {
      next()
      return
    }
    next('/')
  },

  needWorldOwner: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && store.getters['auth/isWorldOwner']) {
      next()
      return
    }
    next('/')
  },

  needSuperAdmin: (to, from, next) => {
    if(store.getters['auth/isAuthenticated'] && store.getters['auth/getProfile'].roles_slugs && store.getters['auth/getProfile'].roles_slugs.includes('admin')) {
      next()
      return
    }
    next('/')
  },

}
export default actions
